// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K0EgF {\n    margin-top: var(--s);\n  }\n  .b-xY1 {\n    display: flex;\n    justify-content: space-between;\n  }\n  .FOpJZ {\n    padding: var(--xs);\n  }\n  .Lp5k- .bp3-skeleton {\n      width: 116px;\n      height: 20px;\n      border-radius: 9px;\n    }\n  ._4Ju69 .bp3-skeleton {\n      width: 200px;\n      height: 20px;\n      border-radius: 9px;\n    }\n  ._5oJ-\\+ .bp3-skeleton {\n      width: 22px;\n      height: 20px;\n      border-radius: 9px;\n    }\n  .BjIj1 .bp3-skeleton {\n      margin-top: 10px;\n      width: 89px;\n      height: 14px;\n      border-radius: 9px;\n    }\n  .OHhwM .bp3-skeleton {\n      margin-top: 10px;\n      width: 100%;\n      height: var(--l);\n      border-radius: 9px;\n    }\n  .pyQMj {\n    display: flex;\n    justify-content: flex-end;\n  }\n  .pyQMj .bp3-skeleton {\n      margin-top: 10px;\n      width: 86px;\n      height: var(--l);\n      border-radius: 9px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SegmentCardLoadingIndicator_card": "K0EgF",
	"SegmentCardLoadingIndicator_headerRow": "b-xY1",
	"SegmentCardLoadingIndicator_loadingIndicatorContainer": "FOpJZ",
	"SegmentCardLoadingIndicator_segmentsListLabel": "Lp5k-",
	"SegmentCardLoadingIndicator_title": "_4Ju69",
	"SegmentCardLoadingIndicator_actionsButton": "_5oJ-+",
	"SegmentCardLoadingIndicator_territoriesCountRow": "BjIj1",
	"SegmentCardLoadingIndicator_tagsRow": "OHhwM",
	"SegmentCardLoadingIndicator_footerRow": "pyQMj"
};
export default ___CSS_LOADER_EXPORT___;
