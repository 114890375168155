import React, { useEffect, useMemo, useState } from 'react';

import { Spinner } from '@blueprintjs/core';
import { Checkmark, ChevronLeft, Close, RulerAlt, WarningAlt } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';
import { Field, Form, Formik } from 'formik';
import clonedeep from 'lodash.clonedeep';

import IconButton from 'components/Buttons/IconButton/IconButton';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
//eslint-disable-next-line no-restricted-imports
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import { KeyValue } from 'components/models';
import SearchableSelectMenu from 'components/SearchableSelectMenu/SearchableSelectMenu';

import {
  filterInheritedRulesAndRemoveEmptyHierarchies,
  formatRowData,
  removeTerritoryHierarchiesFromRule
} from 'app/components/AdvancedGrid/GridHelpers/TerritoryGrid/territoryGridUtils';
import { formatFilterForRequest } from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleHelpers';
import AddHierarchyButton from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/AddHierarchyButton/AddHierarchyButton';
import DirtyPrompt from 'app/components/DirtyFormPrompt/DirtyPrompt';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import CompactRuleGroup from 'app/components/TerritoryRuleBuilder/CompactRuleGroup';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';

import { NewRuleDefinitionResponse, RuleIncExc } from 'app/graphql/generated/graphqlApolloTypes';
import { useUpsertTerritoryRule } from 'app/graphql/hooks/useUpsertTerritoryRule';
import { useGetRootHierarchiesLazy } from 'app/graphql/queries/getRootHierarchies';
import { useGetTerritoryRulesLazy } from 'app/graphql/queries/getTerritoryRules';

import { useIsOverlayBattleCardSelected } from 'app/hooks/useIsOverlayBattleCardSelected';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  FlattenedFilter,
  GridFields,
  HierarchySpec,
  HierarchyType,
  HierarchyTypeName,
  OperatorType,
  RuleBeingEdited,
  RulePartType,
  TerritoryRuleHierarchyWithInfo,
  WrappedRuleDefinition
} from 'app/models';

import block from 'utils/bem-css-modules';
import { getLeafNodes } from 'utils/helpers/index';
import { formatMessage } from 'utils/messages/utils';

import errorImage from 'assets/pngs/401.png';

import style from './UpsertTerritoryRuleView.module.pcss';

const EMPTY_NEW_RULE: NewRuleDefinitionResponse = {
  base: { inclusions: [], exclusions: [] },
  modifiers: { inclusions: [] }
};

const getInitialRuleDef = (ruleDefinition: NewRuleDefinitionResponse) => {
  if (ruleDefinition && 'base' in ruleDefinition && ruleDefinition?.base) {
    return filterInheritedRulesAndRemoveEmptyHierarchies(clonedeep(ruleDefinition));
  } else {
    return EMPTY_NEW_RULE;
  }
};

const b = block(style);

interface UpsertTerritoryRuleViewProps {
  onBackButtonClick: () => void;
  territoryIdToEdit: number;
}

const UpsertTerritoryRuleView: React.FC<UpsertTerritoryRuleViewProps> = ({
  onBackButtonClick,
  territoryIdToEdit
}: UpsertTerritoryRuleViewProps) => {
  const { tdrTreeLookupMap, selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedBattleCardId, selectedQuotaComponentId, selectedBattleCardMeasures } = useBattleCard();
  const { setRefreshGrid, sortModel } = useGrid();
  const { selectedPlanningCycle } = useScope();
  const [isSingleAccountTerritoryOn] = useTreatment(SplitFeatures.SINGLE_ACCOUNT_TERRITORY);
  const showToast = useShowToast();

  const isOverlayBattleCard = useIsOverlayBattleCardSelected();

  const isEditMode = territoryIdToEdit !== null;

  const territoryRootHierarchy: HierarchySpec = {
    rootKey: 'Territory ID',
    rootName: HierarchyTypeName.OVERLAY_TERRITORIES,
    rootHierarchyId: 0,
    hierarchyType: HierarchyType.OverlayHierarchy,
    numOfMembers: 0
  };

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isBattleCardSelected = !selectedPillId || selectedPillIdPlanTargets === 'battlecard';

  useEffect(() => {
    if (territoryIdToEdit) {
      getTerritoryRuleToEdit({
        fetchPolicy: 'no-cache',
        variables: {
          startRow: 1,
          endRow: 1,
          quotaComponentId: selectedQuotaComponentId,
          territoryGroupId: +selectedPillId || null,
          battlecardId: isBattleCardSelected ? +selectedBattleCardId : null,
          measureId: selectedBattleCardMeasures?.[0]?.measureId || 0,
          sorting: sortModel,
          dimensionsAndModifiersLimit: null,
          searchInput: {
            filters: formatFilterForRequest({
              [GridFields.TERRITORY_ID]: {
                filterType: 'text',
                type: 'equals',
                filter: territoryIdToEdit,
                filterTo: 'string'
              }
            })
          }
        }
      });
    }
  }, [territoryIdToEdit]);

  useEffect(() => {
    if (selectedPlanningCycle?.id) {
      getRootHierarchies({ variables: { planningCycleId: selectedPlanningCycle.id } });
    }
  }, [selectedPlanningCycle]);

  const [getTerritoryRuleToEdit, { data: territoryRulesData, loading: territoryRuleToEditDataLoading }] =
    useGetTerritoryRulesLazy({
      onError() {
        showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
      },
      onCompleted(data) {
        const formattedRuleToEdit = formatRowData(data?.getTerritoryRules?.territoryRules)?.[0];

        setNewRuleDefinition(getInitialRuleDef(formattedRuleToEdit?.ruleDefinition));

        const initialTerritoryGroupMenuItem = territoryGroupMenuItems.find(
          (territoryGroupMenuItem) => +territoryGroupMenuItem.value === formattedRuleToEdit.territoryGroupId
        );

        setInitialTerritoryGroupMenuItem(initialTerritoryGroupMenuItem);
        setNewInheritsFrom(formattedRuleToEdit?.inheritsFrom);
      }
    });

  const [
    getRootHierarchies,
    { data: rootHierarchiesData, loading: rootHierarchiesDataLoading, error: getRootHierarchiesError }
  ] = useGetRootHierarchiesLazy({
    fetchPolicy: 'network-only',
    onError() {
      showToast(formatMessage('UNABLE_TO_RETRIEVE_HIERARCHIES'), 'danger');
    }
  });

  const successToastMessage = formatMessage(isEditMode ? 'UPDATE_TERRITORY_SUCCESS' : 'CREATE_NEW_TERRITORY_SUCCESS');
  const errorToastMessage = formatMessage(isEditMode ? 'UPDATE_TERRITORY_ERROR' : 'CREATE_NEW_TERRITORY_ERROR');

  const [upsertTerritoryRule, { loading: upsertTerritoryRuleLoading }] = useUpsertTerritoryRule(
    successToastMessage,
    errorToastMessage,
    () => {
      setRefreshGrid(true);
      onBackButtonClick();
    }
  );

  const [territoryGroupMenuItems, setTerritoryGroupMenuItems] = useState<KeyValue<string>[]>(null);
  const [initialTerritoryGroupMenuItem, setInitialTerritoryGroupMenuItem] = useState<{ key: string; value: string }>(
    null
  );

  const rootHierarchies = rootHierarchiesData?.getRootHierarchies;
  const territoryRuleToEdit = territoryRulesData?.getTerritoryRules?.territoryRules?.[0];

  const [newRuleDefinition, setNewRuleDefinition] = useState<NewRuleDefinitionResponse>(EMPTY_NEW_RULE);
  const [newInheritsFrom, setNewInheritsFrom] = useState([]);
  const [rulesBeingEdited, setRulesBeingEdited] = useState([]);
  const [searchText, setSearchText] = useState('');

  // since rule is not managed by Formik, we need to manually track whether it's dirty
  const [isRuleDirty, setIsRuleDirty] = useState(false);

  const [isSingleAccountTerritoryEnabled, setIsSingleAccountTerritoryEnabled] = useState<boolean>(false);

  const customerHierarchy: HierarchySpec = rootHierarchies?.find((rootHierarchy) => {
    return rootHierarchy?.hierarchyType === HierarchyType.CustomerAccountHierarchy;
  });

  const handleAdd = (hierarchy: HierarchySpec, isOverride: boolean) => {
    if (hierarchy) {
      const newFilter: FlattenedFilter = {
        field: `${hierarchy.hierarchyType}.hierarchyId`,
        rootHierarchyId: hierarchy.rootHierarchyId,
        rootHierarchyName: hierarchy.rootName,
        fieldIdsDetails: { [OperatorType.EQUAL]: [] }
      };
      const newHierarchy = {
        rootHierarchyId: hierarchy.rootHierarchyId,
        rootHierarchyName: hierarchy.rootName,
        hierarchyType: `${hierarchy.hierarchyType}.hierarchyId`,
        contains: []
      };
      setIsRuleDirty(true);

      if (isOverride) {
        setNewRuleDefinition({
          ...newRuleDefinition,
          modifiers: {
            inclusions: [...newRuleDefinition.modifiers.inclusions, newHierarchy]
          }
        });
      } else {
        setNewRuleDefinition({
          ...newRuleDefinition,
          base: {
            inclusions: [...newRuleDefinition.base.inclusions, newHierarchy],
            exclusions: [...newRuleDefinition.base.exclusions, newHierarchy]
          }
        });
      }

      setRulesBeingEdited([
        ...rulesBeingEdited,
        {
          ...newFilter,
          rulePartType: isOverride ? RulePartType.OVERRIDE : RulePartType.BASE
        }
      ]);
    }
  };

  const updateRuleDefinition = (rule: NewRuleDefinitionResponse) => {
    setIsRuleDirty(true);
    setNewRuleDefinition(rule);
  };

  const updateInheritsFrom = (inheritsFrom: TerritoryRuleHierarchyWithInfo[]) => {
    setIsRuleDirty(true);
    setNewInheritsFrom(inheritsFrom);
    setNewRuleDefinition(removeTerritoryHierarchiesFromRule(newRuleDefinition));
  };

  useEffect(() => {
    const overlayTerritoryIndex = disabledHierarchies.indexOf(HierarchyTypeName.OVERLAY_TERRITORIES);
    if (newInheritsFrom?.length && overlayTerritoryIndex === -1) {
      disabledHierarchies.push(HierarchyTypeName.OVERLAY_TERRITORIES);
    } else if (!newInheritsFrom?.length && overlayTerritoryIndex !== -1) {
      disabledHierarchies.splice(overlayTerritoryIndex, 1);
    }
  }, [newInheritsFrom, newRuleDefinition]);

  useEffect(() => {
    const tdrTree = tdrTreeLookupMap?.[selectedBattleCardId];

    if (tdrTree?.length) {
      // get all leaf territory groups
      const territoryGroupLeafNodes = [];
      tdrTree.forEach((treeNode) => getLeafNodes(treeNode, territoryGroupLeafNodes));

      // convert every territory group leaf node into a menu item
      const newTerritoryGroupMenuItems = [];
      territoryGroupLeafNodes.forEach((territoryGroup) => {
        newTerritoryGroupMenuItems.push({
          value: String(territoryGroup?.territoryGroupId),
          key: territoryGroup?.name
        });
      });

      setTerritoryGroupMenuItems(newTerritoryGroupMenuItems);
    }
  }, [tdrTreeLookupMap, selectedBattleCardId]);

  const initialFormValues = {
    territoryGroup: {
      value: initialTerritoryGroupMenuItem?.value || '',
      key: initialTerritoryGroupMenuItem?.key || ''
    } as KeyValue<string>,
    territoryId: isEditMode ? territoryRuleToEdit?.territoryId : '',
    territoryName: isEditMode ? territoryRuleToEdit?.territoryName : ''
  };

  const shouldDisableSave =
    (newRuleDefinition?.base?.inclusions?.length === 0 &&
      newRuleDefinition?.base?.exclusions?.length === 0 &&
      newRuleDefinition?.modifiers?.inclusions?.length === 0 &&
      newInheritsFrom?.length === 0) ||
    rulesBeingEdited?.length > 0;

  const removeTypenameFromInheritsFrom = ({ ruleId, territoryId, territoryName }) => ({
    ruleId,
    territoryId,
    territoryName
  });

  const handleSubmit = async (values) => {
    const ruleDefinition: WrappedRuleDefinition = { definition: newRuleDefinition };

    if (
      isSingleAccountTerritoryOn &&
      isSingleAccountTerritoryEnabled &&
      newRuleDefinition?.base?.inclusions?.[0]?.contains.length > 1
    ) {
      return;
    }
    await upsertTerritoryRule({
      variables: {
        ruleId: isEditMode ? +territoryRuleToEdit?.ruleId : null,
        territoryId: values?.territoryId,
        territoryName: values?.territoryName,
        territoryGroupId: +values?.territoryGroup?.value,
        effectiveDate: territoryRuleToEdit?.effectiveDate,
        endDate: territoryRuleToEdit?.endDate,
        ...ruleDefinition,
        inheritsFrom: newInheritsFrom?.map(removeTypenameFromInheritsFrom) || []
      }
    });
  };

  const inclusionHierarchies =
    newRuleDefinition?.base?.inclusions?.map((inclusion: RuleIncExc) => inclusion?.rootHierarchyName) ?? [];
  const exclusionHierarchies =
    newRuleDefinition?.base?.exclusions?.map((inclusion: RuleIncExc) => inclusion?.rootHierarchyName) ?? [];
  const disabledHierarchies = [...new Set([...inclusionHierarchies, ...exclusionHierarchies])];
  if (territoryRuleToEdit?.inheritsFrom?.length) disabledHierarchies.push(HierarchyTypeName.OVERLAY_TERRITORIES);

  const filteredMenuItems = useMemo(() => {
    return territoryGroupMenuItems?.filter(({ key }) => {
      return key.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText, territoryGroupMenuItems]);

  const hasCustomerModifier = newRuleDefinition?.modifiers?.inclusions.find((inclusion: RuleIncExc) => {
    return inclusion?.rootHierarchyType === HierarchyType.CustomerAccountHierarchy;
  });

  const isEditingModifier = rulesBeingEdited.find((ruleBeingEdited: RuleBeingEdited) => {
    return ruleBeingEdited.rulePartType === RulePartType.OVERRIDE;
  });

  const singleAccountTerritorySaveDisabled =
    isSingleAccountTerritoryOn &&
    isSingleAccountTerritoryEnabled &&
    newRuleDefinition?.base?.inclusions?.[0]?.contains.length > 1;

  const hasError = !!getRootHierarchiesError;
  const isLoading = rootHierarchiesDataLoading || territoryRuleToEditDataLoading;

  const hierarchiesForAddBtn =
    isOverlayBattleCard && rootHierarchies ? [...rootHierarchies, territoryRootHierarchy] : rootHierarchies;

  return (
    <div className={b('')}>
      <nav data-testid="navigation-header" className={b('navigationHeader')}>
        <div className={b('navigationHeaderItemsContainer')}>
          <TextButton
            intent="primary"
            icon={<ChevronLeft size={20} className={b('backButtonIcon')} />}
            text={formatMessage('BACK')}
            onClick={onBackButtonClick}
            type="button"
            testId="back-button"
            minimal
          />
          <span data-testid="new-territory-text" className={b('text', { newTerritoryText: true })}>
            {isEditMode ? territoryRuleToEdit?.territoryId : formatMessage('NEW_TERRITORY')}
          </span>
          <span data-testid="forward-slash-text" className={b('text', { slashText: true })}>
            {formatMessage('FORWARD_SLASH')}
          </span>
          <span data-testid="is-editing-text" className={b('text')}>
            {isEditMode ? formatMessage('EDIT') : formatMessage('CREATE')}
          </span>
        </div>
      </nav>
      {isLoading ? (
        <div data-testid="spinner" className={b('spinnerContainer')}>
          <Spinner intent="primary" size={100} />
        </div>
      ) : null}
      {hasError ? (
        <div className={b('errorContainer')} data-testid="error-overlay">
          <img className={b('errorImage')} src={errorImage} alt="" />
          <div className={b('errorText')}>{formatMessage('AN_ERROR_OCCURRED')}</div>
        </div>
      ) : null}
      {!isLoading && !hasError ? (
        <div className={b('body')}>
          <Formik initialValues={initialFormValues} onSubmit={handleSubmit} enableReinitialize>
            {({ dirty: isFormDirty, submitCount }) => {
              return (
                <Form className={b('territoryRuleForm')}>
                  <DirtyPrompt
                    dirty={isFormDirty || isRuleDirty}
                    submitCount={submitCount}
                    data-testid="dirty-form-prompt"
                  />
                  <div className={b('leftPanel')}>
                    <div data-testid="left-panel-header" className={b('leftPanelHeader')}>
                      <div className={b('leftPanelHeaderItemsContainer')}>
                        <span data-testid="territoryid-text" className={b('text', { columnText: true })}>
                          {formatMessage('TERRITORY_ID')}
                        </span>
                        <span data-testid="territory-name-text" className={b('text', { columnText: true })}>
                          {formatMessage('TERRITORY_NAME')}
                        </span>
                        <span data-testid="territory-group-text" className={b('text', { columnText: true })}>
                          {formatMessage('TERRITORY_GROUP')}
                        </span>
                      </div>
                    </div>
                    <div data-testid="left-panel-content" className={b('leftPanelContentContainer')}>
                      <Field
                        component={FormTextInputGroup}
                        name="territoryId"
                        defaultValue={initialFormValues.territoryId}
                        placeholder={formatMessage('INPUT_TEXT')}
                        className={b('inputGroup')}
                        data-testid="territory-id-input"
                      />
                      <Field
                        component={FormTextInputGroup}
                        name="territoryName"
                        defaultValue={initialFormValues.territoryName}
                        placeholder={formatMessage('INPUT_TEXT')}
                        className={b('inputGroup')}
                        data-testid="territory-name-input"
                      />
                      <div className={b('inputGroup', { territoryGroupInput: true })}>
                        <Field
                          component={SearchableSelectMenu}
                          name="territoryGroup"
                          placeHolderText={formatMessage('SELECT')}
                          showErrors={false}
                          onSearch={(searchString) => setSearchText(searchString)}
                          onSearchReset={() => setSearchText('')}
                          showIconInField={false}
                          initialLoadingComplete={true}
                          items={filteredMenuItems}
                          theme="default"
                          className={b('territoryGroupInput')}
                          data-testid="territory-group-input"
                          usePortal
                        />
                      </div>
                    </div>
                  </div>
                  <div className={b('rightPanel')}>
                    <div data-testid="right-panel-header" className={b('rightPanelHeader')}>
                      <span data-testid="territory-rule-text" className={b('text', { columnText: true })}>
                        {formatMessage('TERRITORY_RULE')}
                      </span>
                    </div>
                    <div data-testid="right-panel-content" className={b('rightPanelContentContainer')}>
                      <div className={b('actionToolbar')}>
                        <div className={b('addButtons')}>
                          <AddHierarchyButton
                            icon={<RulerAlt size={20} />}
                            text={formatMessage('ADD_RULE')}
                            hierarchies={hierarchiesForAddBtn}
                            disabledHierarchies={disabledHierarchies}
                            onSelect={(hierarchy) => handleAdd(hierarchy, false)}
                            data-testid="add-rule-button"
                          />
                          <MessageTooltip
                            target={
                              <AddHierarchyButton
                                icon={<WarningAlt size={20} />}
                                text={formatMessage('ADD_OVERRIDE')}
                                hierarchies={
                                  // TODO TQP-9942 Adjust when support for geos is added
                                  [customerHierarchy]
                                }
                                disabledHierarchies={
                                  // if rule has modifiers, disable customer hierarchy from being added again since it is currently the only hierarchy supported
                                  // TODO TQP-9942 Adjust when support for geos is added
                                  hasCustomerModifier || isEditingModifier ? [customerHierarchy?.rootName] : []
                                }
                                onSelect={(hierarchy) => handleAdd(hierarchy, true)}
                                data-testid="add-override-button"
                              />
                            }
                            content={formatMessage('OVERRIDE_RULE_TOOLTIP_ENABLED')}
                            placement={'top'}
                          />
                          {isSingleAccountTerritoryOn && (
                            <div
                              className={b('switchContainer')}
                              data-testid="single-account-hierarchy-switch-button-container"
                            >
                              <DividerV2 vertical />
                              <div className={b('switchButton')}>
                                <SwitchButton
                                  checked={isSingleAccountTerritoryEnabled}
                                  onChange={() => {
                                    setIsSingleAccountTerritoryEnabled(!isSingleAccountTerritoryEnabled);
                                  }}
                                  labelElement={
                                    <span data-testid="single-account-territory-switch-label">
                                      {formatMessage('SINGLE_ACCOUNT_TERRITORY')}
                                    </span>
                                  }
                                  testId="single-account-territory-switch"
                                  intent="primary"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <IconButton
                            type="button"
                            icon={<Close size={20} />}
                            title={formatMessage('CANCEL')}
                            onClick={onBackButtonClick}
                            outlined
                            className={b('cancelBtn')}
                            testId="cancel-rule-btn"
                          />
                          <IconButton
                            type="submit"
                            icon={<Checkmark size={20} />}
                            title={formatMessage('SAVE')}
                            disabled={shouldDisableSave || singleAccountTerritorySaveDisabled}
                            tooltipText={
                              singleAccountTerritorySaveDisabled &&
                              formatMessage('SINGLE_ACCOUNT_TERRITORY_DISABLE_SAVE_TOOLTIP_TEXT')
                            }
                            minimal={false}
                            loading={upsertTerritoryRuleLoading}
                            intent="primary"
                            testId="save-rule-btn"
                          />
                        </div>
                      </div>
                      {newRuleDefinition ? (
                        <CompactRuleGroup
                          ruleDefinition={newRuleDefinition}
                          setRuleDefinition={updateRuleDefinition}
                          rulesBeingEdited={rulesBeingEdited}
                          setRulesBeingEdited={setRulesBeingEdited}
                          data-testid="rule-group"
                          inheritsFrom={newInheritsFrom}
                          setNewInheritsFrom={updateInheritsFrom}
                        />
                      ) : null}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : null}
    </div>
  );
};

export default UpsertTerritoryRuleView;
