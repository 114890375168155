import React, { useState } from 'react';

import { Divider } from '@blueprintjs/core';

import TextButton from 'components/Buttons/TextButton/TextButton';

import DataTrayImportButton from 'app/components/DataTray/DataTrayImportButton/DataTrayImportButton';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';

import { SplitFeatures } from 'app/global/features';

import { useUpsertFieldValues } from 'app/graphql/mutations/upsertFieldValues';

import useTreatment from 'app/hooks/useTreatment';

import { BattlecardType, PlanningCycleModal } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './QuotaGridActionButtons.module.pcss';

const b = block(style);

const MAXIMUM_BULK_UPSERT_QUOTA_FIELDS = 700;
interface QuotaGridActionButtonsProps {
  setOpenedModal: (dataTrayModal: PlanningCycleModal) => void;
}

const QuotaGridActionButtons: React.FC<QuotaGridActionButtonsProps> = ({
  setOpenedModal
}: QuotaGridActionButtonsProps) => {
  const { selectedBattleCardId, battleCardLookupMap } = useBattleCard();
  const { showAccountQuotaDrillIn, hasGridThresholdBeenExceeded, bulkUpsertQuotaGridFields, setRefreshGrid } =
    useGrid();
  const isRootBattleCard = !battleCardLookupMap?.[selectedBattleCardId]?.battlecardParentId;
  const isRollUpBattleCard = battleCardLookupMap?.[selectedBattleCardId]?.battlecardType === BattlecardType.Rollup;

  const isDisabled = !selectedBattleCardId || isRootBattleCard || isRollUpBattleCard || showAccountQuotaDrillIn;
  const [isQuotaGridTotalsSlownessEnabled] = useTreatment(SplitFeatures.QUOTA_GRID_TOTALS_SLOWNESS);
  const [isProcessingBatches, setIsProcessingBatches] = useState(false);
  const [upsertFieldValues] = useUpsertFieldValues();

  const handleUpsert = async () => {
    const input = Object.values(bulkUpsertQuotaGridFields).map((field) => ({
      ...field,
      fieldValue: String(field.fieldValue)
    }));

    if (input.length === 0) return;

    const batches = [];
    for (let i = 0; i < input.length; i += MAXIMUM_BULK_UPSERT_QUOTA_FIELDS) {
      batches.push(input.slice(i, i + MAXIMUM_BULK_UPSERT_QUOTA_FIELDS));
    }

    setIsProcessingBatches(true);

    try {
      for (const batch of batches) {
        await upsertFieldValues({
          variables: {
            input: batch
          }
        });
      }
      setRefreshGrid(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessingBatches(false);
    }
  };

  return (
    <CanUser
      perform={UserAction.TERRITORY_DEFINITION_MODIFY}
      yes={
        <>
          {isQuotaGridTotalsSlownessEnabled && hasGridThresholdBeenExceeded && (
            <>
              <TextButton
                disabled={Object.keys(bulkUpsertQuotaGridFields).length === 0 || isProcessingBatches}
                text={formatMessage('PUBLISH')}
                type="submit"
                testId={'publish-quota-button'}
                tooltipText={formatMessage('PUBLISH_BUTTON_TOOLTIP')}
                tooltipPlacement="left-start"
                intent="primary"
                className={b('publishButton')}
                onClick={handleUpsert}
                loading={isProcessingBatches}
              />
              <Divider className={b('divider')} />
            </>
          )}
          <DataTrayImportButton
            disabled={isDisabled}
            tooltipText={formatMessage('IMPORT_QUOTAS')}
            onClick={() => setOpenedModal(PlanningCycleModal.IMPORT_QUOTAS)}
            data-testid="import-quota-button"
          />
        </>
      }
    />
  );
};

export default QuotaGridActionButtons;
