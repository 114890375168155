import React from 'react';

import { Card, Classes } from '@varicent/components';

import block from 'utils/bem-css-modules';

import style from './SegmentCardLoadingIndicator.module.pcss';

const b = block(style);

const CARDS = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }];

const SegmentCardsLoadingIndicator: React.FC = () => {
  return (
    <div data-testid="segment-card-loading-indicator" className={b('loadingIndicatorContainer')}>
      <div className={b('segmentsListLabel')}>
        <div className={Classes.SKELETON} />
      </div>

      {CARDS.map((currentCard) => (
        <Card key={currentCard.id} className={b('card')} elevation={0}>
          <div className={b('headerRow')}>
            <div className={b('title')}>
              <div className={Classes.SKELETON} />
            </div>
            <div className={b('actionsButton')}>
              <div className={Classes.SKELETON} />
            </div>
          </div>
          <div className={b('territoriesCountRow')}>
            <div className={Classes.SKELETON} />
          </div>
          <div className={b('tagsRow')}>
            <div className={Classes.SKELETON} />
          </div>
          <div className={b('footerRow')}>
            <div className={Classes.SKELETON} />
          </div>
        </Card>
      ))}
    </div>
  );
};

export default SegmentCardsLoadingIndicator;
