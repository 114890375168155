import { gql } from '@apollo/client';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetTerritoryRules, GetTerritoryRulesVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_TERRITORY_RULES = gql`
  query GetTerritoryRules(
    $quotaComponentId: Int!
    $measureId: Int!
    $sorting: GetTerritoryRulesSortModel!
    $searchInput: GetTerritoryRulesSearchInput
    $territoryGroupId: Int
    $battlecardId: Int
    $startRow: Int
    $endRow: Int
    $includeDefinition: Boolean
    $dimensionsAndModifiersLimit: Int
  ) {
    getTerritoryRules(
      input: {
        measureId: $measureId
        quotaComponentId: $quotaComponentId
        sorting: $sorting
        searchInput: $searchInput
        territoryGroupId: $territoryGroupId
        battlecardId: $battlecardId
        startRow: $startRow
        endRow: $endRow
        completeDefinition: $includeDefinition
        dimensionsAndModifiersLimit: $dimensionsAndModifiersLimit
      }
    ) {
      territoryRules {
        effectiveDate
        endDate
        measureGap
        measureValue
        numberOfAccounts
        numberOfAccountsGap
        ruleId
        territoryId
        territoryName
        territoryGroupId
        territoryGroupName
        owners {
          ownerId
          employeeId
          firstName
          lastName
          memberId
          ownership
          effectiveStartDate
          effectiveEndDate
        }
        ruleDefinitionCompressed {
          compressed
          compressedType
        }
        color
        inheritsFrom {
          ruleId
          territoryId
          territoryName
        }
      }
      totalCount
      totalAccountsCount
      totalAccountsMean
      measureValueMean
      measureValueStandardDeviation
    }
  }
`;

export const useGetTerritoryRulesLazy = createUseLazyQuery<GetTerritoryRules, GetTerritoryRulesVariables>(
  GET_TERRITORY_RULES
);
