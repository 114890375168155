import gql from 'graphql-tag';

import { createUseQuery } from 'app/graphql/apolloFactoryHelpers';
import { getOperationName } from 'app/graphql/documentHelpers';
import { GetSegments, GetSegmentsVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_SEGMENTS = gql`
  query GetSegments($deploymentModelId: Int!, $battlecardId: Int!) {
    getDeploymentModelSpec(input: { deploymentModelId: $deploymentModelId, battlecardId: $battlecardId }) {
      battlecards {
        battlecardId
        battlecardName
        segments {
          segmentId
          segmentName
          rootOrphanCount
          orphanCount
          clauses {
            rootHierarchyId
            rootHierarchyName
            hierarchyType
            operator
            hierarchies {
              hierarchyId
              hierarchyName
            }
          }
        }
      }
    }
  }
`;

export const GET_SEGMENTS_NAME = getOperationName(GET_SEGMENTS);

export const useGetSegments = createUseQuery<GetSegments, GetSegmentsVariables>(GET_SEGMENTS);
