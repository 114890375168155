import gql from 'graphql-tag';

import { createUseLazyQuery } from 'app/graphql/apolloFactoryHelpers';
import { GetExportDataStatus, GetExportDataStatusVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const GET_EXPORT_DATA_STATUS = gql`
  query GetExportDataStatus($input: GetExportDataStatusInput!) {
    getExportDataStatus(input: $input) {
      exportId
      status
      message
    }
  }
`;

export const useGetExportDataStatus = createUseLazyQuery<GetExportDataStatus, GetExportDataStatusVariables>(
  GET_EXPORT_DATA_STATUS
);
