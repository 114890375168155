import gql from 'graphql-tag';

import { createUseMutation } from 'app/graphql/apolloFactoryHelpers';
import { ExportData, ExportDataVariables } from 'app/graphql/generated/graphqlApolloTypes';

export const EXPORT_DATA = gql`
  mutation ExportData($input: ExportDataInput!) {
    exportData(input: $input) {
      credentials {
        accessKey
        secretAccessKey
      }
      exportData {
        exportId
        status
        message
      }
      location {
        bucket
        key
      }
    }
  }
`;

export const useExportDataMutation = createUseMutation<ExportData, ExportDataVariables>(EXPORT_DATA);
