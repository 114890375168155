import React, { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLazyQuery } from '@apollo/client';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import buildActivityDrillInColumnDef from 'app/components/AdvancedGrid/GridHelpers/ActivityDrillIn/ActivityDrillInColumnDef';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { BLOCK_SIZE, SHOW_LOADING_AFTER } from 'app/global/variables';

import {
  GetAggregatedActivitiesForTerritoryWithMeasureValue,
  GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_AGGREGATED_ACTIVITIES_FOR_TERRITORY_WITH_MEASURE_VALUE } from 'app/graphql/queries/getAggregatedActivitiesForTerritoryWithMeasureValue';

import { MeasureType } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AggregatedActivitiesGrid.module.pcss';

const b = block(style);
interface AggregatedActivitiesGridProps {
  selectedHierarchy: string[];
}

interface BaseMeasureType {
  measureId: number;
  measureName: string;
}

const AggregatedActivitiesGrid: React.FC<AggregatedActivitiesGridProps> = ({
  selectedHierarchy
}: AggregatedActivitiesGridProps) => {
  const { selectedBattleCardId, selectedQuotaComponentId, selectedBattleCardMeasures } = useBattleCard();
  const { selectedTerritory } = useGrid();
  const { measuresData, getMeasures } = usePlanTargets();
  const { selectedPlanningCycle } = useScope();
  const { defaultReportingCurrency } = useLocalization();

  const [isLoading, setIsLoading] = useState(false);
  const [activitiesSortModel, setActivitiesSortModel] = useState(null);

  const containerRef = useRef(null);

  const rowRef = {
    current: {
      ...containerRef?.current,
      offsetHeight: CELL_HEIGHT,
      offsetWidth: containerRef?.current?.offsetWidth
    }
  };

  const getMeasuresData = (lst: BaseMeasureType[]): number[] => {
    return lst
      .filter(
        (measure) =>
          measure.measureName === MeasureType.PRIOR_YEAR_ACTUAL ||
          measure.measureName === MeasureType.TRAILING_TWELVE_MONTHS
      )
      .map((measure) => measure.measureId);
  };

  useEffect(() => {
    if (!selectedBattleCardMeasures?.length && !measuresData) {
      getMeasures(selectedPlanningCycle?.id);
    }
  }, []);

  const [
    getAggregatedActivities,
    { data: aggregatedActivitiesForTerritory, loading: aggregatedActivitiesForTerritoryLoading, fetchMore }
  ] = useLazyQuery<
    GetAggregatedActivitiesForTerritoryWithMeasureValue,
    GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
  >(GET_AGGREGATED_ACTIVITIES_FOR_TERRITORY_WITH_MEASURE_VALUE, {
    fetchPolicy: 'network-only',
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const totalCount =
    aggregatedActivitiesForTerritory?.getAggregatedActivitiesForTerritoryWithMeasureValue
      ?.territoryAggregatedActivitiesCount || 0;

  useEffect(() => {
    if (selectedBattleCardMeasures?.length || measuresData) {
      getAggregatedActivities({
        variables: {
          battlecardId: +selectedBattleCardId ?? 0,
          territoryGroupId: selectedTerritory?.territoryGroupId,
          territoryId: selectedTerritory?.territoryId,
          quotaComponentId: selectedQuotaComponentId,
          startRow: 1,
          endRow: BLOCK_SIZE,
          sorting: activitiesSortModel,
          hierarchies: selectedHierarchy,
          // right now in aggregatedActivitiesGrid there is no place to choose which measure or measures to display
          // hardcode to use Prior year sales and Trailing 12 months as default if avaliable
          measures: getMeasuresData(selectedBattleCardMeasures?.length ? selectedBattleCardMeasures : measuresData),
          ruleId: selectedTerritory?.ruleId
        }
      });
    }
  }, [measuresData, selectedHierarchy]);

  const handleModelUpdate = (gridEvent) => {
    const aggregatedActivitiesTotals =
      aggregatedActivitiesForTerritory?.getAggregatedActivitiesForTerritoryWithMeasureValue
        ?.territoryAggregatedActivitiesTotals;
    const pinnedBottomTotals = {};
    aggregatedActivitiesTotals?.forEach((total) => {
      pinnedBottomTotals[total.measureName] = total.measureValue;
    });
    gridEvent.api.setPinnedBottomRowData([pinnedBottomTotals]);
  };

  const handleSortUpdate = (gridEvent) => {
    const columnsList = gridEvent.columnApi.columnModel.gridColumnsMap;
    const sortedColumn = Object.values(columnsList).filter((hierarchy) => {
      return hierarchy['sort'];
    });
    let model;
    if (sortedColumn?.length > 0) {
      model = {
        sortModel: {
          colId: sortedColumn[0]['colId'],
          sort: sortedColumn[0]['sort']
        }
      };
    }
    setActivitiesSortModel(model);
  };

  const onGridReady = (params) => {
    if (activitiesSortModel) {
      const model = {
        state: [{ colId: activitiesSortModel.sortModel.colId, sort: activitiesSortModel.sortModel.sort, sortIndex: 0 }]
      };
      params.columnApi?.applyColumnState(model);
    }
    if (totalCount > 0 && totalCount < BLOCK_SIZE) {
      // Waiting for the DOM to render asynchrously so empty rows can be
      // hidden when the total count is below 10

      setTimeout(() => {
        const count = totalCount;
        const nodeList = document.getElementsByClassName('ag-cell-wrapper');
        for (let i = count; i <= BLOCK_SIZE; i++) {
          //Hiding empty rows when the total count is below 10, due to ag grid limitation
          if (nodeList[i]?.['style']) {
            nodeList[i]['style']['visibility'] = 'hidden';
          }
        }
      }, 0);
    }
  };

  useEffect(() => {
    const timeoutTerritory = setTimeout(() => {
      if (aggregatedActivitiesForTerritoryLoading) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }, SHOW_LOADING_AFTER);

    return () => {
      clearTimeout(timeoutTerritory);
    };
  }, [aggregatedActivitiesForTerritoryLoading]);

  const aggregatedGridProps = {
    headerHeight: CELL_HEIGHT,
    rowHeight: 40,
    rowClass: style.DataTray_gridRow,
    suppressScrollOnNewData: true,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    onSortChanged: handleSortUpdate,
    onModelUpdated: handleModelUpdate,
    defaultColDef: {
      resizable: true
    },
    // eslint-disable-next-line no-restricted-syntax
    rowModelType: 'infinite',
    cacheBlockSize: BLOCK_SIZE,
    datasource: {
      rowCount: totalCount,
      getRows: async (params) => {
        if (params?.endRow === BLOCK_SIZE) {
          const aggregatedListInitial =
            aggregatedActivitiesForTerritory?.getAggregatedActivitiesForTerritoryWithMeasureValue?.territoryAggregatedActivitiesList?.map(
              (item) => {
                const formattedItem = {};
                item?.hierarchies?.forEach((hierarchy) => {
                  formattedItem[hierarchy.name] = hierarchy.val;
                });
                item?.measures?.forEach((measure) => {
                  formattedItem[measure.measureName] = measure.measureValue;
                });
                return formattedItem;
              }
            );
          params?.successCallback(aggregatedListInitial ?? [], totalCount);
        } else if (getPaginationCheck(params.startRow, totalCount)) {
          const fetchMoreFiles = await fetchMore<
            GetAggregatedActivitiesForTerritoryWithMeasureValue,
            GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
          >({
            variables: {
              startRow: params?.startRow + 1,
              endRow: params?.endRow,
              battlecardId: +selectedBattleCardId,
              territoryGroupId: selectedTerritory?.territoryGroupId,
              territoryId: selectedTerritory?.territoryId,
              quotaComponentId: selectedQuotaComponentId,
              sorting: activitiesSortModel,
              hierarchies: selectedHierarchy,
              measures: getMeasuresData(selectedBattleCardMeasures?.length ? selectedBattleCardMeasures : measuresData),
              ruleId: selectedTerritory?.ruleId
            }
          });
          const aggregatedList =
            fetchMoreFiles?.data?.getAggregatedActivitiesForTerritoryWithMeasureValue?.territoryAggregatedActivitiesList?.map(
              (item) => {
                const formattedItem = {};
                item?.hierarchies?.forEach((hierarchy) => {
                  formattedItem[hierarchy.name] = hierarchy.val;
                });
                item?.measures?.forEach((measure) => {
                  formattedItem[measure.measureName] = measure.measureValue;
                });
                return formattedItem;
              }
            );
          params?.successCallback(aggregatedList, totalCount);
        }
      }
    }
  };

  const activityData =
    aggregatedActivitiesForTerritory?.getAggregatedActivitiesForTerritoryWithMeasureValue
      ?.territoryAggregatedActivitiesList;
  const isAggregatedActivities = true;
  const activityDataString = activityData ? JSON.stringify(activityData) : '';

  const shouldShowNoRowsOverlay = activityData?.length === 0 && !isLoading;

  return (
    <div className={b()} data-testid="aggregated-activities-container" ref={containerRef}>
      <div className={b('territoryDetails')}>
        <div className={b('territoryDetailsGrid')}>
          {shouldShowNoRowsOverlay ? (
            <div className={b('gridOverlayContainer')} data-testid="no-territories-overlay">
              <div className={b('gridOverlayText')}>{formatMessage('NO_ACTIVITY_FILES')}</div>
            </div>
          ) : null}
          {!shouldShowNoRowsOverlay ? (
            <AdvancedGrid
              gridOptions={aggregatedGridProps}
              data={activityDataString}
              columnDefs={
                activityData &&
                buildActivityDrillInColumnDef(
                  activityData,
                  defaultReportingCurrency,
                  isAggregatedActivities,
                  totalCount,
                  rowRef
                )
              }
              data-testid="aggregated-activities-grid"
              onGridReady={onGridReady}
              gridHeight={containerRef?.current?.offsetHeight}
              gridWidth={containerRef?.current?.offsetWidth}
              showGridLoading={isLoading}
              noDataMessage={formatMessage('NO_ACTIVITY_FILES')}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AggregatedActivitiesGrid;
