import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { RowNode } from '@ag-grid-community/core';

import ActivitiesGrid from 'app/components/DataTray/ActivitiesGrid/ActivitiesGrid';
import AggregatedActivitiesGrid from 'app/components/DataTray/AggregatedActivitiesGrid/AggregatedActivitiesGrid';
import TerritoryGrid from 'app/components/DataTray/TerritoryGrid/TerritoryGrid';
import UpsertTerritoryRuleView from 'app/components/DataTray/TerritoryGrid/UpsertTerritoryRuleView/UpsertTerritoryRuleView';
import TerritoryMapV3 from 'app/components/TerritoryMap/TerritoryMapV3';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { HierarchyQuerySpec, CategoryName, GridFields } from 'app/models';

import block from 'utils/bem-css-modules';

import style from './TerritoryGridContainer.module.pcss';

const b = block(style);

interface TerritoryGridContainerProps {
  hierarchies: HierarchyQuerySpec;
  selectedHierarchy: string;
  setSelectedNodes: (nodes: RowNode) => void;
  setMoveTerritoriesTGTypes: Dispatch<SetStateAction<Array<number>>>;
  onIsFilterPresentChange: (isFilterActive: boolean) => void;
}

const TerritoryGridContainer: React.FC<TerritoryGridContainerProps> = ({
  hierarchies,
  selectedHierarchy,
  setSelectedNodes,
  setMoveTerritoriesTGTypes,
  onIsFilterPresentChange
}: TerritoryGridContainerProps) => {
  const { selectedBattleCardId } = useBattleCard();
  const { showActivities, showAggregatedActivities, showUpsertTerritoryRuleView, setShowUpsertTerritoryRuleView } =
    useGrid();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { isDataTrayMapOpen, isMultiMapOpen } = useMapVariant();
  const { selectedTerritoryGroupId } = useMapContextRedistributor();

  const [territoryIdToEdit, setTerritoryIdToEdit] = useState<number>(null);

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isUnassignedSelected = selectedPillId === CategoryName.UNASSIGNED;

  // if battle card selection changes, hide drill-in view
  useEffect(() => {
    setShowUpsertTerritoryRuleView(false);
  }, [selectedBattleCardId]);

  // territory grid tab
  if (showActivities) {
    // show activity grid
    return <ActivitiesGrid isUnassignedSelected={isUnassignedSelected} data-testid="activities-grid" />;
  } else if (showAggregatedActivities) {
    // show aggregated activity grid
    // selectedHierarchy is a required field. So the default will need to include all hierarchy
    const hierarchyKeys = hierarchies?.getRootHierarchies?.map((hierarchy) => hierarchy.rootKey);
    return (
      <AggregatedActivitiesGrid
        selectedHierarchy={selectedHierarchy ? [selectedHierarchy] : hierarchyKeys}
        data-testid="aggregated-activities-grid"
      />
    );
  } else if (isDataTrayMapOpen && !!selectedTerritoryGroupId && !isMultiMapOpen) {
    return (
      <div className={b('mapWrapper')}>
        <TerritoryMapV3 />
      </div>
    );
  } else if (selectedBattleCardId || selectedPillId) {
    // show territory grid
    if (showUpsertTerritoryRuleView) {
      return (
        <UpsertTerritoryRuleView
          territoryIdToEdit={territoryIdToEdit}
          onBackButtonClick={() => {
            setShowUpsertTerritoryRuleView(false);
            setTerritoryIdToEdit(null);
          }}
          data-testid="upsert-territory-rule-view"
        />
      );
    } else {
      return (
        <TerritoryGrid
          data-testid="territory-grid"
          onEditTerritory={(rowData) => {
            setShowUpsertTerritoryRuleView(true);
            setTerritoryIdToEdit(rowData[GridFields.TERRITORY_ID]);
          }}
          setSelectedNodes={setSelectedNodes}
          setMoveTerritoriesTGTypes={setMoveTerritoriesTGTypes}
          onIsFilterPresentChange={onIsFilterPresentChange}
        />
      );
    }
  } else {
    return null;
  }
};

export default TerritoryGridContainer;
