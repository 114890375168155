import { useCallback, useEffect, useRef, useState } from 'react';

import { useMapRulesPaging } from 'app/components/TerritoryMap/hooks/useMapRulesPaging';
import { useMapRulesPagingFetchVariables } from 'app/components/TerritoryMap/hooks/useMapRulesPagingFetchVariables';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SplitFeatures } from 'app/global/features';

import { GetTerritoryRulesForMapVariables, SourceInput } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import { RuleChangeCause, RuleChangeCauses } from 'app/models';

export const useMapRules = (
  input: {
    source?: SourceInput;
  },
  isWorkerLoading: boolean
): {
  loading: boolean;
  refetch: (cause: RuleChangeCause) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(true);

  const { primaryHierarchy } = useMapContextRedistributor();
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const fetchVariables = useMapRulesPagingFetchVariables(input);
  const [isUndoEnabled] = useTreatment(SplitFeatures.MAP_UNDO);

  const postMessage = useMapWorkerPostMessage();
  const initializedFor = useRef<GetTerritoryRulesForMapVariables | null>(null);

  const { fetchAllTerritories, loading } = useMapRulesPaging(fetchVariables);

  useEffect(() => {
    const automaticFetchCause = getAutomaticFetchCause();
    if (fetchVariables && automaticFetchCause) {
      refetch(automaticFetchCause);
      initializedFor.current = fetchVariables;
    }
  }, [fetchVariables]);

  const getAutomaticFetchCause = (): RuleChangeCause | null => {
    let automaticFetchCause: RuleChangeCause | null = null;
    if (
      fetchVariables?.territoryGroupId !== initializedFor.current?.territoryGroupId ||
      fetchVariables?.source !== initializedFor.current?.source
    ) {
      automaticFetchCause = RuleChangeCauses.InitialLoad;
    } else if (
      fetchVariables?.quotaComponentId !== initializedFor.current?.quotaComponentId ||
      fetchVariables?.measureId !== initializedFor.current?.measureId
    ) {
      automaticFetchCause = RuleChangeCauses.MeasureChange;
    }

    return automaticFetchCause;
  };

  useEffect(() => {
    if (!initializedFor.current) return;
    refetch(RuleChangeCauses.LimitationChange);
  }, [chosenCustomHierarchy, isUndoEnabled, primaryHierarchy]);

  const isMountedRef = useIsMountedRef();

  const refetch = useCallback(
    async (cause: RuleChangeCause) => {
      const result = await fetchAllTerritories();
      if (!isMountedRef.current || !result) return;
      postMessage({
        cause,
        type: 'rules-change',
        rules: result ?? [],
        chosenCustomHierarchy,
        isUndoEnabled,
        primaryHierarchy
      });
    },
    [chosenCustomHierarchy, isUndoEnabled, primaryHierarchy]
  );

  useEffect(() => {
    if (loading || !fetchVariables) {
      setIsLoading(true);
    }
  }, [loading, fetchVariables]);

  useEffect(() => {
    if (!isWorkerLoading && fetchVariables) {
      setIsLoading(false);
    }
  }, [isWorkerLoading]);

  return {
    refetch,
    loading: isLoading
  };
};

const useIsMountedRef = () => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};
