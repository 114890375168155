import React, { useRef, useState, useEffect } from 'react';

import { GridApi, ColDef, ServerSideStoreType } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import DirtyPrompt from 'app/components/DirtyFormPrompt/DirtyPrompt';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useDataTray } from 'app/contexts/dataTrayProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE } from 'app/global/variables';

import {
  GetTerritoryQuotaDistributionVariables,
  GetTerritoryQuotaVariables
} from 'app/graphql/generated/graphqlApolloTypes';
import { useUpsertMeasureValueLazy } from 'app/graphql/mutations/upsertMeasureValue';
import { useGetTerritoryQuota } from 'app/graphql/queries/getMeasures';
import { useGetTerritoryQuotaDistribution } from 'app/graphql/queries/getTerritoryQuotaDistribution';

import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import { SheetType } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import buildHierarchyQuotaDistributionData from './buildHierarchyQuotaDistributionData';
import style from './HierarchyQuotaDistribution.module.pcss';
import buildHierarchyQuotaDistributionColumnDefs from './HierarchyQuotaDistributionColumnDefs';

const b = block(style);

interface HierarchyQuotaDistributionProps {
  quotaSheetId: number;
}

const HierarchyQuotaDistribution: React.FC<HierarchyQuotaDistributionProps> = ({ quotaSheetId }) => {
  const { battleCardLookupMap, selectedBattleCardId, selectedQuotaComponentId, setSelectedBattleCardId } =
    useBattleCard();
  const { defaultReportingCurrency } = useLocalization();
  const {
    selectedQuotaDrillInTerritory,
    setBulkUpsertQuotaGridFields,
    hasGridThresholdBeenExceeded,
    refreshGrid,
    setRefreshGrid
  } = useGrid();

  const { allDataSheets } = useData();
  const { setIsEditingGrid } = useDataTray();
  const showToast = useShowToast();

  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { selectedPillIdPlanTargets, getPlanTargets } = usePlanTargets();
  const {
    selectedPlanningCycle,
    selectedDeploymentModelId,
    showUnsaveChangesWarningDialog,
    setShowUnsaveChangesWarningDialog
  } = useScope();
  const containerRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(null);
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [shouldRefetchTotals, setShouldRefetchTotals] = useState(false);
  const [isQuotaGridTotalsSlownessEnabled] = useTreatment(SplitFeatures.QUOTA_GRID_TOTALS_SLOWNESS);
  const isQuotaGridBulkUpsertEnabled = isQuotaGridTotalsSlownessEnabled && hasGridThresholdBeenExceeded;

  const quotaDistributionSheetId = allDataSheets.find(
    (sheet) => sheet.sheetType === SheetType.QUOTA_DISTRIBUTION_SHEET
  )?.sheetId;

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;
  const isBattleCardSelected = !selectedPillId || selectedPillIdPlanTargets === 'battlecard';

  // since we are calling the same api for the grid rows and grid total. It's just what being returned is different
  // In the case of fetching the totals, we do not need to pass in startRow, endRow and ruleId casue they only matters when we are fetching the rows
  const getQueryVariables = (startRow, endRow, isFetchingTotal = false): GetTerritoryQuotaDistributionVariables => {
    let variables;

    if (
      isBattleCardSelected &&
      selectedBattleCardId &&
      (!selectedPillId || selectedPillId === 'battlecard') &&
      selectedQuotaComponentId
    ) {
      //Request when a battle card is selected
      variables = getSelectedBattleCardVariables(startRow, endRow, isFetchingTotal);
    } else if (selectedBattleCardId && selectedPillId && selectedQuotaComponentId) {
      //Request when a pill is selected
      variables = getSelectedTerritoryPillVariables(startRow, endRow, isFetchingTotal);
    } else if (!selectedBattleCardId && selectedPillId && selectedQuotaComponentId) {
      //Request when in the contributor view
      variables = getContributorViewVariables(startRow, endRow, isFetchingTotal);
    }
    return { input: variables };
  };

  const getSelectedBattleCardVariables = (startRow, endRow, isFetchingTotal = false) => {
    const battlecardId = +selectedBattleCardId;
    const quotaComponentId = selectedQuotaComponentId;

    return {
      battlecardId,
      quotaComponentId,
      sheetId: quotaDistributionSheetId,
      ...(isFetchingTotal ? {} : { ruleId: selectedQuotaDrillInTerritory.ruleId, startRow, endRow })
    };
  };

  const getSelectedTerritoryPillVariables = (startRow, endRow, isFetchingTotal = false) => {
    const battlecardId = +selectedBattleCardId;
    const territoryGroupId = +selectedPillId;
    const quotaComponentId = selectedQuotaComponentId;

    return {
      battlecardId,
      quotaComponentId,
      territoryGroupId,
      sheetId: quotaDistributionSheetId,
      ...(isFetchingTotal ? {} : { ruleId: selectedQuotaDrillInTerritory.ruleId, startRow, endRow })
    };
  };

  const getContributorViewVariables = (startRow, endRow, isFetchingTotal = false) => {
    const territoryGroupId = +selectedPillId;
    const quotaComponentId = selectedQuotaComponentId;

    return {
      quotaComponentId,
      territoryGroupId,
      sheetId: quotaDistributionSheetId,
      ...(isFetchingTotal ? {} : { ruleId: selectedQuotaDrillInTerritory.ruleId, startRow, endRow })
    };
  };

  const {
    data,
    loading: isQuotaBreakdownLoading,
    fetchMore: fetchMoreBreakdown
  } = useGetTerritoryQuotaDistribution({
    variables: getQueryVariables(1, BLOCK_SIZE),
    fetchPolicy: 'network-only',
    skip: !quotaDistributionSheetId || !selectedQuotaDrillInTerritory.ruleId,
    onError() {
      showToast(formatMessage('QUOTA_GRID_ERROR'), 'danger');
    }
  });

  // TODO: quota grid loading state for pinned bottom row TQP-10450 https://varicent.atlassian.net/browse/TQP-10450
  const { input: getTotalVariables } = getQueryVariables(1, BLOCK_SIZE, true);
  getTotalVariables.sheetId = quotaSheetId;
  getTotalVariables.filter = { ruleId: selectedQuotaDrillInTerritory.ruleId };
  const {
    data: totalData,
    loading: isTotalDataLoading,
    refetch: refetchTotals
  } = useGetTerritoryQuota({
    variables: getTotalVariables as GetTerritoryQuotaVariables,
    fetchPolicy: 'network-only',
    skip: !quotaDistributionSheetId || !selectedQuotaDrillInTerritory.ruleId,
    onError() {
      showToast(formatMessage('QUOTA_GRID_ERROR'), 'danger');
    }
  });

  const [upsertQuotaAdjustment] = useUpsertMeasureValueLazy({
    onCompleted() {
      // trigger ag grid api to refresh rows
      gridApi.refreshServerSideStore({ purge: false });
      // trigger useEffect to refresh totals
      setShouldRefetchTotals(true);
      getPlanTargets(
        selectedBattleCardId,
        selectedQuotaComponentId,
        selectedPlanningCycle.id,
        selectedDeploymentModelId
      );
    },
    onError() {
      showToast(formatMessage('UPDATE_MEASURE_VALUE_ERROR'), 'danger');
    }
  });

  const quotaBreakdownData = data?.getTerritoryQuota?.periodicQuotaDistribution;
  const totalCount = data?.getTerritoryQuota?.quotaDistributionByHierarchiesTotalCount;

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  useEffect(() => {
    // only set column def once when the grid data first load
    if (data?.getTerritoryQuota?.periodicQuotaDistribution && !isQuotaBreakdownLoading && !columnDefs) {
      setColumnDefs(
        buildHierarchyQuotaDistributionColumnDefs(
          data.getTerritoryQuota.periodicQuotaDistribution,
          battleCardLocalCurrencyCode || defaultReportingCurrency,
          b('totalsHeader'),
          upsertQuotaAdjustment,
          selectedQuotaDrillInTerritory.territoryId,
          selectedQuotaDrillInTerritory.territoryGroupId,
          selectedQuotaDrillInTerritory.ruleId,
          +selectedBattleCardId,
          setBulkUpsertQuotaGridFields,
          setIsEditingGrid,
          isQuotaGridBulkUpsertEnabled
        )
      );
    }
  }, [data, isQuotaBreakdownLoading]);

  useEffect(() => {
    if (gridApi) {
      gridApi.setColumnDefs(columnDefs);
    }
  }, [columnDefs, gridApi]);

  useEffect(() => {
    // set pinned bottom row data
    const periodicData = totalData?.getTerritoryQuota?.periodicTerritories;
    if (gridApi && !isTotalDataLoading && periodicData) {
      const pinnedData = buildHierarchyQuotaDistributionData(periodicData, selectedQuotaComponentId);
      gridApi.setPinnedBottomRowData(pinnedData);
    }
  }, [gridApi, totalData, isTotalDataLoading]);

  useEffect(() => {
    if (shouldRefetchTotals) {
      refetchTotals();
      setShouldRefetchTotals(false);
    }
  }, [shouldRefetchTotals]);

  useEffect(() => {
    if (refreshGrid) {
      refetchTotals();
      gridApi?.refreshServerSideStore({ purge: false });
      setBulkUpsertQuotaGridFields({});
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  const handleOnGridReady = (gridEvent) => {
    setGridApi(gridEvent.api);
  };

  const gridOptions = {
    onGridReady: handleOnGridReady,
    rowModelType: 'serverSide',
    cacheBlockSize: BLOCK_SIZE,
    serverSideStoreType: 'partial' as ServerSideStoreType,
    serverSideDatasource: {
      getRows: async (params) => {
        if (params.request.endRow === BLOCK_SIZE && params.api.getRenderedNodes().length === 1) {
          const gridData = buildHierarchyQuotaDistributionData(quotaBreakdownData, selectedQuotaComponentId);
          params.success({ rowData: gridData, rowCount: totalCount });
        } else {
          const startRow = params?.request?.startRow + 1;
          const endRow = params?.request?.endRow;
          const variables = getQueryVariables(startRow, endRow);
          const fetchMore = await fetchMoreBreakdown({
            variables
          });
          const moreRows = buildHierarchyQuotaDistributionData(
            fetchMore?.data?.getTerritoryQuota?.periodicQuotaDistribution,
            selectedQuotaComponentId
          );
          params.success({
            rowData: moreRows,
            rowCount: fetchMore?.data?.getTerritoryQuota?.quotaDistributionByHierarchiesTotalCount
          });
        }
      }
    }
  };

  const gridWidth = containerRef?.current?.offsetWidth;
  const gridHeight = containerRef?.current?.offsetHeight;
  const onClose = () => {
    setSelectedBattleCardId(null);
    setBulkUpsertQuotaGridFields({});
    setShowUnsaveChangesWarningDialog(false);
    setIsEditingGrid(false);
  };

  const onCancel = () => {
    setShowUnsaveChangesWarningDialog(false);
  };

  return (
    <div className={b('hierarchyQuotaDrillInWrapper')} ref={containerRef} data-testid="hierarchy-quota-drill-in">
      {isQuotaGridBulkUpsertEnabled && (
        <DirtyPrompt
          dirty={false}
          submitCount={0}
          shouldUseCustomPrompt={showUnsaveChangesWarningDialog}
          onClose={onClose}
          onSubmit={onCancel}
          titleMessage={formatMessage('UNSAVED_CHANGES_TITLE')}
          bodyMessage={formatMessage('UNSAVED_CHANGES_BODY')}
          cancelButtonText={formatMessage('LEAVE_SHEETS')}
          data-testid="dirty-form-prompt"
        />
      )}
      {isQuotaBreakdownLoading || quotaBreakdownData?.length ? (
        <AdvancedGrid
          gridOptions={gridOptions}
          gridWidth={gridWidth}
          gridHeight={gridHeight}
          columnDefs={columnDefs}
          data-testid="hierarchy-quota-drill-in-grid"
          showGridLoading={isQuotaBreakdownLoading}
        />
      ) : (
        <div className={b('gridOverlayContainer')} data-testid="no-data-overlay">
          <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
        </div>
      )}
    </div>
  );
};

export default HierarchyQuotaDistribution;
