// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f6wH1 {\n  margin-top: var(--s);\n}\n\n  .E9mLw {\n    display: flex;\n    flex-direction: column;\n  }\n\n  ._9VK1E {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  .ZE1B9 {\n    display: flex;\n  }\n\n  .E1Jo9 {\n    width: 100%;\n  }\n\n  .cirRv {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--text-heading);\n  }\n\n  .pF9Cl {\n    margin-bottom: var(--xs);\n    display: inline-block;\n  }\n\n  .QhRZ7 {\n    color: var(--intent-danger) !important;\n  }\n\n  .vymw0 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  ._5Zl\\+P {\n    display: flex;\n    width: 100%;\n  }\n\n  .j9lq5 {\n    display: flex;\n    width: 100%;\n    justify-content: flex-end;\n  }\n\n  .j9lq5 .bp3-button-text {\n      padding-right: 0 !important;\n    }\n\n  .hiQjo {\n    margin-top: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SegmentCard": "f6wH1",
	"SegmentCard_cardContainer": "E9mLw",
	"SegmentCard_header": "_9VK1E",
	"SegmentCard_infoContainer": "ZE1B9",
	"SegmentCard_info": "E1Jo9",
	"SegmentCard_title": "cirRv",
	"SegmentCard_territoryCount": "pF9Cl",
	"SegmentCard_deleteActionButton": "QhRZ7",
	"SegmentCard_footer": "vymw0",
	"SegmentCard_tags": "_5Zl+P",
	"SegmentCard_applyButton": "j9lq5",
	"SegmentCard_orphanedHierarchiesCalloutContainer": "hiQjo"
};
export default ___CSS_LOADER_EXPORT___;
