import uniq from 'lodash.uniq';

import { Permission, UserRoleType } from 'app/models';

export enum PermissionFunctionality {
  PLAN = 'plan',
  MANAGE = 'manage',
  MAPS = 'maps',
  SELLER_ASSIGNMENT = 'seller_assignment',
  TERRITORY_DEFINITION_AND_ASSIGNMENT = 'territory_definition_assignment',
  TERRITORY_REBALANCING = 'territory_rebalancing',
  ACCOUNT_QUOTA = 'account_quota',
  SELLER_QUOTA = 'seller_quota',
  TERRITORY_QUOTA = 'territory_quota'
}

export enum UserAction {
  // static actions
  PLANNING_CYCLES_VIEW = 'view:planning_cycles',
  PLANNING_CYCLES_MODIFY = 'modify:planning_cycles',
  BATTLE_CARD_CANVAS_VIEW = 'view:battle_card_canvas',
  BATTLE_CARD_CANVAS_MODIFY = 'modify:battle_card_canvas',
  CONTRIBUTOR_PAGE_VIEW = 'view:contributor_page',
  CONTRIBUTOR_PAGE_MODIFY = 'modify:contributor_page',
  TERRITORY_DEFINITION_VIEW = 'view:territory_definition',
  TERRITORY_DEFINITION_MODIFY = 'modify:territory_definition',
  COMMAND_CENTER_VIEW = 'view:command_center',
  TERRITORY_WORKFLOW_MODIFY = 'modify:territory_workflow',
  MAP_UNASSIGNED_VIEW = 'view:map_uassigned',
  MAP_PINS_VIEW = 'view:map_pins',
  MAP_PINS_EDIT = 'edit:map_pins',
  MAP_RULE_EDIT = 'edit:map:rule', // To be Decomposed in future
  MAP_RECOLOR = 'recolor:map',
  AUDIT_TRAIL_EXPORT = 'export:audit_trail',
  ACCOUNT_RULE_GRID_REASSIGN = 'reassign:account_grid',
  MODIFY_CHANGE_REQUESTS = 'modify:change_requests',
  ACCOUNT_RULE_GRID_VIEW_UNASSIGNED = 'view:account_rule_grid_unassigned',
  SCENARIOS_MODIFY = 'modify:scenarios',
  RUN_COINSORT = 'run:coinsort',
  GENERATE_API_KEY = 'create:api_key',

  // dynamic actions
  PLAN_VIEW = 'view:plan',
  MANAGE_VIEW = 'view:manage',
  MAPS_VIEW = 'view:maps',
  MAPS_SETTINGS_VIEW = 'view:maps_settings',
  SELLER_ASSIGNMENT_VIEW = 'view:seller_assignment',
  SELLER_ASSIGNMENT_EDIT = 'edit:seller_assignment',
  TERRITORY_DEFINITION_AND_ASSIGNMENT_VIEW = 'view:territory_definition_assignment',
  TERRITORY_DEFINITION_AND_ASSIGNMENT_EDIT = 'edit:territory_definition_assignment',
  TERRITORY_REBALANCING_VIEW = 'view:territory_rebalancing',
  TERRITORY_REBALANCING_EDIT = 'edit:territory_rebalancing',
  ACCOUNT_QUOTA_VIEW = 'view:account_quota',
  ACCOUNT_QUOTA_EDIT = 'edit:account_quota',
  SELLER_QUOTA_VIEW = 'view:seller_quota',
  SELLER_QUOTA_EDIT = 'edit:seller_quota',
  TERRITORY_QUOTA_VIEW = 'view:territory_quota',
  TERRITORY_QUOTA_EDIT = 'edit:territory_quota'
}

export enum PermissionActions {
  VIEW = 'view',
  EDIT = 'edit'
}

export const STATIC_USER_PERMISSIONS = {
  [UserRoleType.CONTRIBUTOR]: [
    UserAction.PLANNING_CYCLES_VIEW,
    UserAction.CONTRIBUTOR_PAGE_VIEW,
    UserAction.CONTRIBUTOR_PAGE_MODIFY,
    UserAction.TERRITORY_DEFINITION_VIEW
  ],
  [UserRoleType.ADMIN]: [
    UserAction.MAPS_SETTINGS_VIEW,
    UserAction.PLANNING_CYCLES_VIEW,
    UserAction.PLANNING_CYCLES_MODIFY,
    UserAction.BATTLE_CARD_CANVAS_VIEW,
    UserAction.BATTLE_CARD_CANVAS_MODIFY,
    UserAction.CONTRIBUTOR_PAGE_MODIFY,
    UserAction.TERRITORY_DEFINITION_VIEW,
    UserAction.TERRITORY_DEFINITION_MODIFY,
    UserAction.COMMAND_CENTER_VIEW,
    UserAction.TERRITORY_WORKFLOW_MODIFY,
    UserAction.MAP_UNASSIGNED_VIEW,
    UserAction.MAP_PINS_VIEW,
    UserAction.MAP_PINS_EDIT,
    UserAction.MAP_RULE_EDIT,
    UserAction.MAP_RECOLOR,
    UserAction.AUDIT_TRAIL_EXPORT,
    UserAction.ACCOUNT_RULE_GRID_REASSIGN,
    UserAction.MODIFY_CHANGE_REQUESTS,
    UserAction.ACCOUNT_RULE_GRID_VIEW_UNASSIGNED,
    UserAction.SCENARIOS_MODIFY,
    UserAction.RUN_COINSORT,
    UserAction.GENERATE_API_KEY
  ]
} as const;

export type StaticUserActions =
  | (typeof STATIC_USER_PERMISSIONS)[UserRoleType.CONTRIBUTOR][number]
  | (typeof STATIC_USER_PERMISSIONS)[UserRoleType.ADMIN][number];

const STATIC_USER_ACTIONS: StaticUserActions[] = uniq([
  ...STATIC_USER_PERMISSIONS[UserRoleType.CONTRIBUTOR],
  ...STATIC_USER_PERMISSIONS[UserRoleType.ADMIN]
]);

export const isStatic = (userAction: UserAction): userAction is StaticUserActions => {
  return STATIC_USER_ACTIONS.includes(userAction as StaticUserActions);
};

export const hasStaticPermission = (userAction: StaticUserActions, userRole: UserRoleType) => {
  return STATIC_USER_PERMISSIONS[userRole]?.includes(userAction);
};

export const DYNAMIC_USER_ACTIONS = Object.values(UserAction).filter((userAction) => !isStatic(userAction));

export const getPermissionsByRole = (permissions: Permission[], role: UserRoleType) => {
  return permissions?.filter((permission: Permission) => {
    return permission.roleName === role;
  });
};

export const getPermissionsByAction = (permissions: Permission[], userAction: UserAction) => {
  return permissions?.filter((permission: Permission) => {
    return permission.actionName === userAction;
  });
};

export const permissionsInclude = (permissions: Permission[], userAction: UserAction) => {
  return !!getPermissionsByAction(permissions, userAction)?.length;
};
