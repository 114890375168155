import { useRef, useState } from 'react';

import { MapRulesPagingFetchVariables } from 'app/components/TerritoryMap/hooks/useMapRulesPagingFetchVariables';

import { GetTerritoryRulesForMap_getTerritoryRules_territoryRules } from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { useGetTerritoryRulesForMap } from 'app/graphql/queries/getTerritoryRulesForMap';

import useShowToast from 'app/hooks/useShowToast';
import { useSplitPageSize } from 'app/hooks/useSplitPageSize';

import { startPerformanceTimer } from 'utils/helpers/analyticsReporter';
import { formatMessage } from 'utils/messages/utils';

export const useMapRulesPaging = (
  fetchVariables: MapRulesPagingFetchVariables
): {
  loading: boolean;
  fetchAllTerritories: () => Promise<GetTerritoryRulesForMap_getTerritoryRules_territoryRules[]>;
} => {
  const pageSize = useSplitPageSize('getTerritoriesForMap');
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useShowToast();

  const { fetchMore, refetch } = useGetTerritoryRulesForMap({
    variables: fetchVariables,
    skip: true,
    onError: () => {
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
    }
  });

  const canonicalRequestId = useRef(0);

  const getRulesForMap = async (
    fetchVariables: MapRulesPagingFetchVariables
  ): Promise<GetTerritoryRulesForMap_getTerritoryRules_territoryRules[]> => {
    canonicalRequestId.current += 1;
    const localRequestId = canonicalRequestId.current;
    setIsLoading(true);
    try {
      const result = await getPagedRulesForMap(fetchVariables, pageSize);
      if (canonicalRequestId.current !== localRequestId) return null;
      setIsLoading(false);
      return result;
    } catch (error) {
      if (localRequestId !== canonicalRequestId.current) return null;
      setIsLoading(false);
      handleError(null, error as Error);
      showToast(formatMessage('TERRITORY_GRID_ERROR'), 'danger');
      return null;
    }
  };

  const getPagedRulesForMap = async (
    fetchVariables: MapRulesPagingFetchVariables,
    pageSize: number
  ): Promise<GetTerritoryRulesForMap_getTerritoryRules_territoryRules[]> => {
    const timer = startPerformanceTimer('getMapRules');
    const firstPage = await refetch(fetchVariables);

    const totalRowCount = firstPage?.data?.getTerritoryRules.totalCount;

    const pageCount = Math.ceil(totalRowCount / pageSize);

    const pagePromises: Promise<GetTerritoryRulesForMap_getTerritoryRules_territoryRules[]>[] = [
      Promise.resolve(firstPage?.data?.getTerritoryRules.territoryRules)
    ];

    for (let pageIndex = 1; pageIndex < pageCount; pageIndex++) {
      pagePromises.push(
        fetchMore({
          variables: {
            startRow: pageSize * pageIndex + 1,
            endRow: pageSize * (pageIndex + 1)
          }
        }).then((result) => {
          return Promise.resolve(result.data?.getTerritoryRules.territoryRules);
        })
      );
    }

    const territoryRulePages = await Promise.all(pagePromises);

    timer.stop();

    return territoryRulePages.flat();
  };

  const fetchAllTerritories = () => {
    const allTerritories = getRulesForMap(fetchVariables);

    return allTerritories;
  };

  return { fetchAllTerritories, loading: isLoading };
};
