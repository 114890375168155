import React from 'react';

import { Close } from '@carbon/icons-react';
import { CalloutV2, Intent } from '@varicent/components';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { useUpsertSegment } from 'app/graphql/mutations/upsertSegment';
import { GET_SEGMENTS_NAME } from 'app/graphql/queries/getSegments';

import useShowToast from 'app/hooks/useShowToast';

import { SegmentFilterClause } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

interface OrphanedHierarchiesCalloutProps {
  orphanCount: number;
  segmentId: number;
  segmentName: string;
  filter: SegmentFilterClause[];
}

const OrphanedHierarchiesCallout: React.FC<OrphanedHierarchiesCalloutProps> = ({
  orphanCount,
  segmentId,
  segmentName,
  filter
}) => {
  const showToast = useShowToast();
  const { selectedBattleCardId } = useMapContextRedistributor();

  const [upsertSegment, { loading: isUpsertSegmentLoading }] = useUpsertSegment({
    refetchQueries: [GET_SEGMENTS_NAME],
    awaitRefetchQueries: true,
    onError: () => {
      showToast(formatMessage('SEGMENT_EDIT_ERROR'), 'danger');
    }
  });

  const dismissOrphans = async () => {
    await upsertSegment({
      variables: {
        input: {
          segmentId,
          segmentName,
          battlecardId: parseInt(selectedBattleCardId),
          clauses: filter.map((clause) => ({
            rootHierarchyId: clause.rootHierarchyId,
            hierarchyType: clause.hierarchyType,
            ids: clause.hierarchies.map((hierarchy) => hierarchy.hierarchyId),
            operator: clause.operator
          }))
        }
      }
    });
  };

  return (
    <CalloutV2
      data-testid="orphaned-hierarchies-callout"
      hideActionText
      loading={isUpsertSegmentLoading}
      title={
        orphanCount === 1
          ? formatMessage('ORPHANED_HIERARCHIES_DELETED_SINGULAR', { orphanCount })
          : formatMessage('ORPHANED_HIERARCHIES_DELETED', { orphanCount })
      }
      action={() => dismissOrphans()}
      actionIcon={Close}
      actionText={formatMessage('CLOSE')}
      intent={Intent.DANGER}
    >
      {formatMessage('ORPHANED_HIERARCHIES_WARNING')}
    </CalloutV2>
  );
};

export default OrphanedHierarchiesCallout;
