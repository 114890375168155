import clonedeep from 'lodash.clonedeep';

import {
  GetTerritoryRules_getTerritoryRules_territoryRules,
  NewRuleDefinitionResponse,
  RuleIncExc,
  RuleIncExcGroup
} from 'app/graphql/generated/graphqlApolloTypes';

import {
  FlattenedFilter,
  GridFields,
  HierarchyTypeName,
  OperatorType,
  RootHierarchyField,
  TerritoryRuleHierarchyWithInfo
} from 'app/models';

import { CompressableTypeMap, Compressed, CompressionUtil } from 'utils/helpers/CompressionUtil';

const filterTerritoriesFn = (incExc: RuleIncExc) => incExc.rootHierarchyName !== HierarchyTypeName.OVERLAY_TERRITORIES;

export const removeTerritoryHierarchiesFromRule = (rule: NewRuleDefinitionResponse): NewRuleDefinitionResponse => {
  const filteredRule = clonedeep(rule);
  filteredRule.base.inclusions = filteredRule.base.inclusions.filter(filterTerritoriesFn);
  filteredRule.base.exclusions = filteredRule.base.exclusions.filter(filterTerritoriesFn);
  return filteredRule;
};

const convertContains = (contains) => {
  return {
    ruleId: contains.ruleId,
    fieldId: contains.hierarchyId,
    key: contains.key,
    fieldName: contains.name
  };
};

const convertInheritsFromTerritory = (territory) => {
  return {
    ruleId: territory.ruleId,
    fieldId: territory.territoryId,
    fieldName: territory.territoryName
  };
};

const territoryHierarchy = {
  field: RootHierarchyField.OverlayHierarchyField,
  rootHierarchyId: 0,
  rootHierarchyName: HierarchyTypeName.OVERLAY_TERRITORIES
};

const filterInheritedTerritories = (contains) => {
  return !contains.inheritedFrom;
};

export const filterEmptyHierarchies = (incExc: RuleIncExc): boolean => incExc.contains.length !== 0;

export const filterInheritedRulesAndRemoveEmptyHierarchies = (
  ruleDefinition: NewRuleDefinitionResponse
): NewRuleDefinitionResponse => {
  if (!ruleDefinition) {
    return {};
  }
  const {
    base: { inclusions, exclusions },
    modifiers: { inclusions: modifierInclusions }
  } = ruleDefinition;

  ruleDefinition.base.inclusions = inclusions
    ?.map((inclusion: RuleIncExc) => {
      inclusion.contains = inclusion.contains.filter(filterInheritedTerritories);
      return inclusion;
    })
    .filter(filterEmptyHierarchies);

  ruleDefinition.base.exclusions = exclusions
    ?.map((exclusion: RuleIncExc) => {
      exclusion.contains = exclusion.contains.filter(filterInheritedTerritories);
      return exclusion;
    })
    .filter(filterEmptyHierarchies);

  ruleDefinition.modifiers.inclusions = modifierInclusions
    ?.map((inclusion: RuleIncExc) => {
      inclusion.contains = inclusion.contains.filter(filterInheritedTerritories);
      return inclusion;
    })
    .filter(filterEmptyHierarchies);

  return ruleDefinition;
};

// Transform the base rule inclusion exclusion arrays into a flattened filter array for the UI
export const combineBaseRuleInclusionsAndExclusions = (
  base: RuleIncExcGroup,
  inheritsFrom: TerritoryRuleHierarchyWithInfo[] = []
): FlattenedFilter[] => {
  const { inclusions, exclusions } = base;
  const uniqueHierarchies = [...inclusions, ...exclusions]
    .map((inclusionExclusion: RuleIncExc) => ({
      rootHierarchyName: inclusionExclusion.rootHierarchyName,
      rootHierarchyId: inclusionExclusion.rootHierarchyId,
      field: inclusionExclusion.hierarchyType
    }))
    .reduce((uniqueHierarchyArray, hierarchy) => {
      const hierarchyExisits =
        uniqueHierarchyArray.findIndex(
          (existingHierarchy) => existingHierarchy.rootHierarchyName === hierarchy.rootHierarchyName
        ) >= 0;
      if (hierarchyExisits) return uniqueHierarchyArray;
      uniqueHierarchyArray.push(hierarchy);
      return uniqueHierarchyArray;
    }, []);

  if (inheritsFrom.length) {
    uniqueHierarchies.unshift(territoryHierarchy);
  }

  return uniqueHierarchies.map((hierarchy) => {
    const { field, rootHierarchyName, rootHierarchyId } = hierarchy;
    if (rootHierarchyName === HierarchyTypeName.OVERLAY_TERRITORIES) {
      return {
        field,
        rootHierarchyId,
        rootHierarchyName,
        fieldIdsDetails: {
          [OperatorType.EQUAL]: inheritsFrom.map(convertInheritsFromTerritory)
          // [OperatorType.NOT_EQUAL]: []
        },
        inclusionTotal: inheritsFrom.length
      };
    }
    const filteredInclusions: RuleIncExc = inclusions.filter(
      (inclusion: RuleIncExc) => inclusion.rootHierarchyName === rootHierarchyName
    )[0];
    const filteredExclusions: RuleIncExc = exclusions.filter(
      (exclusion: RuleIncExc) => exclusion.rootHierarchyName === rootHierarchyName
    )[0];

    const flattenedFilter = {
      field,
      rootHierarchyId,
      rootHierarchyName,
      fieldIdsDetails: {
        [OperatorType.EQUAL]: filteredInclusions?.contains?.map(convertContains),
        [OperatorType.NOT_EQUAL]: filteredExclusions?.contains?.map(convertContains)
      },
      inclusionTotal: filteredInclusions?.containsTotal,
      exclusionTotal: filteredExclusions?.containsTotal
    };
    return flattenedFilter;
  });
};

export const formatRowData = (
  rowData: GetTerritoryRules_getTerritoryRules_territoryRules[],
  measureValueMean = 0,
  measureValueStandardDeviation = 0,
  startRow = 1
) => {
  return rowData.filter(Boolean).map((row, index) => {
    const decompressedNewRule = CompressionUtil.decompress(
      row.ruleDefinitionCompressed as Compressed<keyof CompressableTypeMap>
    ) as NewRuleDefinitionResponse;
    return {
      [GridFields.TERRITORY_ID]: row.territoryId,
      [GridFields.TERRITORY_NAME]: row.territoryName,
      [GridFields.MEASURE_VALUE]: row.measureValue,
      [GridFields.NUMBER_OF_ACCOUNTS]: row.numberOfAccounts,
      [GridFields.TERRITORY_RULE]: decompressedNewRule,
      index: index + startRow,
      measureGap: row.measureGap || 0,
      numberOfAccountsGap: row.numberOfAccountsGap || 0,
      mean: measureValueMean,
      standardDeviation: measureValueStandardDeviation,
      ruleId: row.ruleId,
      territoryGroupId: row.territoryGroupId,
      territoryGroupName: row.territoryGroupName,
      owners: row.owners,
      color: row.color,
      effectiveDate: row.effectiveDate,
      endDate: row.endDate,
      inheritsFrom: row.inheritsFrom
    };
  });
};
