import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import {
  GetTerritoryRulesSortModel,
  GetTerritoryRules_getTerritoryRules_territoryRules,
  SortDirection,
  SortableGetTerritoryRulesGridCols,
  TerritoryFieldValuesInput
} from 'app/graphql/generated/graphqlApolloTypes';

import { useContextSafe } from 'app/hooks/useContextSafe';

import {
  BaseContext,
  Owner,
  SelectedCell,
  SelectedQACell,
  SelectedQuotaDrillInTerritory,
  SelectedTDACell,
  SelectedTerritory
} from 'app/models';

export interface GridContextValues extends BaseContext {
  isQuotaAdjustmentEditable: boolean;
  setIsQuotaAdjustmentEditable: Dispatch<SetStateAction<boolean>>;
  isCommentEditable: boolean;
  setIsCommentEditable: Dispatch<SetStateAction<boolean>>;
  sortModel: GetTerritoryRulesSortModel;
  setSortModel: Dispatch<SetStateAction<GetTerritoryRulesSortModel>>;
  refreshGrid: boolean;
  setRefreshGrid: Dispatch<SetStateAction<boolean>>;
  selectedTerritory: SelectedTerritory;
  setSelectedTerritory: Dispatch<SetStateAction<SelectedTerritory>>;
  showActivities: boolean;
  setShowActivities: Dispatch<SetStateAction<boolean>>;
  showAggregatedActivities: boolean;
  setShowAggregatedActivities: Dispatch<SetStateAction<boolean>>;
  showAccountQuotaDrillIn: boolean;
  setShowAccountQuotaDrillIn: Dispatch<SetStateAction<boolean>>;
  showManageTerritoryQuotaDrillIn: boolean;
  setShowManageTerritoryQuotaDrillIn: Dispatch<SetStateAction<boolean>>;
  showUpsertTerritoryRuleView: boolean;
  setShowUpsertTerritoryRuleView: Dispatch<SetStateAction<boolean>>;
  owners: Owner[];
  setOwners: Dispatch<SetStateAction<Owner[]>>;
  blockSize: number;
  setBlockSize: Dispatch<SetStateAction<number>>;
  resetValues: () => void;
  selectedRowData: unknown;
  setSelectedRowData: Dispatch<SetStateAction<unknown>>;
  selectedQuotaDrillInTerritory: SelectedQuotaDrillInTerritory;
  setSelectedQuotaDrillInTerritory: Dispatch<SetStateAction<SelectedQuotaDrillInTerritory>>;
  errorItems: Record<string, string[]>[];
  setErrorItems: Dispatch<Record<string, string[]>[]>;
  selectedCell: SelectedCell | SelectedQACell | SelectedTDACell;
  setSelectedCell: Dispatch<SetStateAction<SelectedCell | SelectedQACell | SelectedTDACell>>;
  selectedTerritoryRules: GetTerritoryRules_getTerritoryRules_territoryRules[];
  setSelectedTerritoryRules: Dispatch<SetStateAction<GetTerritoryRules_getTerritoryRules_territoryRules[]>>;
  showUpsertSellerAssignmentView: boolean;
  setShowUpsertSellerAssignmentView: Dispatch<SetStateAction<boolean>>;
  isBulkDeleteChecked: boolean;
  setIsBulkDeleteChecked: Dispatch<SetStateAction<boolean>>;
  bulkDeleteTerritoryJobKey: string;
  setBulkDeleteTerritoryJobKey: Dispatch<SetStateAction<string>>;
  bulkDeleteExclusionIds: number[];
  setBulkDeleteExclusionIds: Dispatch<SetStateAction<number[]>>;
  bulkDeleteInclusionIds: number[];
  setBulkDeleteInclusionIds: Dispatch<SetStateAction<number[]>>;
  hasGridThresholdBeenExceeded: boolean;
  setHasGridThresholdBeenExceeded: Dispatch<SetStateAction<boolean>>;
  bulkUpsertQuotaGridFields: Record<string, TerritoryFieldValuesInput>;
  setBulkUpsertQuotaGridFields: Dispatch<SetStateAction<Record<string, TerritoryFieldValuesInput>>>;
}

export const GridContext = React.createContext<GridContextValues | null>(null);
GridContext.displayName = 'GridContext';

export const defaultSortModel = {
  sort: SortDirection.asc,
  colId: SortableGetTerritoryRulesGridCols.territoryId
};

export const GridProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isQuotaAdjustmentEditable, setIsQuotaAdjustmentEditable] = useState(true); // the quota adjustment column on quota grid
  const [isCommentEditable, setIsCommentEditable] = useState(true); // the comment column on quota grid
  const [sortModel, setSortModel] = useState<GetTerritoryRulesSortModel>(defaultSortModel);
  const [refreshGrid, setRefreshGrid] = useState<boolean>(false);
  const [selectedQuotaDrillInTerritory, setSelectedQuotaDrillInTerritory] =
    useState<SelectedQuotaDrillInTerritory>(null);
  const [selectedTerritory, setSelectedTerritory] = useState<SelectedTerritory>();
  const [owners, setOwners] = useState<Owner[]>(null);
  const [showActivities, setShowActivities] = useState<boolean>(false);
  const [showAggregatedActivities, setShowAggregatedActivities] = useState<boolean>(false);
  const [showAccountQuotaDrillIn, setShowAccountQuotaDrillIn] = useState<boolean>(false);
  const [showManageTerritoryQuotaDrillIn, setShowManageTerritoryQuotaDrillIn] = useState<boolean>(false);
  const [showUpsertTerritoryRuleView, setShowUpsertTerritoryRuleView] = useState<boolean>(false);
  const [blockSize, setBlockSize] = useState<number>(0);
  const [selectedRowData, setSelectedRowData] = useState<unknown>(null);
  const [errorItems, setErrorItems] = useState<Record<string, string[]>[]>([]);
  const [selectedCell, setSelectedCell] = useState<SelectedCell | SelectedQACell>(null);
  const [selectedTerritoryRules, setSelectedTerritoryRules] = useState<
    GetTerritoryRules_getTerritoryRules_territoryRules[]
  >([]);
  const [showUpsertSellerAssignmentView, setShowUpsertSellerAssignmentView] = useState<boolean>(false);
  const [isBulkDeleteChecked, setIsBulkDeleteChecked] = useState<boolean>(false);
  const [bulkDeleteTerritoryJobKey, setBulkDeleteTerritoryJobKey] = useState<string>(null);
  const [bulkDeleteExclusionIds, setBulkDeleteExclusionIds] = useState<number[]>([]);
  const [bulkDeleteInclusionIds, setBulkDeleteInclusionIds] = useState<number[]>([]);
  const [hasGridThresholdBeenExceeded, setHasGridThresholdBeenExceeded] = useState<boolean>(false);
  const [bulkUpsertQuotaGridFields, setBulkUpsertQuotaGridFields] = useState<Record<string, TerritoryFieldValuesInput>>(
    {}
  );

  const resetValues = () => {
    setIsQuotaAdjustmentEditable(true);
    setIsCommentEditable(true);
    setSortModel(defaultSortModel);
    setRefreshGrid(false);
    setBlockSize(0);
    setOwners(null);
    setShowActivities(false);
    setShowAggregatedActivities(false);
    setShowUpsertTerritoryRuleView(false);
    setSelectedRowData(null);
    setErrorItems([]);
    setSelectedTerritory(null);
    setSelectedCell(null);
    setSelectedTerritoryRules([]);
    setShowUpsertSellerAssignmentView(false);
    setShowAccountQuotaDrillIn(false);
    setShowManageTerritoryQuotaDrillIn(false);
    setSelectedQuotaDrillInTerritory(null);
    setIsBulkDeleteChecked(false);
    setBulkDeleteTerritoryJobKey(null);
    setBulkDeleteExclusionIds([]);
    setBulkDeleteInclusionIds([]);
    setHasGridThresholdBeenExceeded(false);
    setBulkUpsertQuotaGridFields({});
  };

  // Prevent forced re-render on components that are reading these values,
  // unless certain values have changed.
  const values = useMemo(
    () => ({
      isQuotaAdjustmentEditable,
      setIsQuotaAdjustmentEditable,
      isCommentEditable,
      setIsCommentEditable,
      resetValues,
      sortModel,
      setSortModel,
      refreshGrid,
      setRefreshGrid,
      owners,
      setOwners,
      showActivities,
      setShowActivities,
      showAggregatedActivities,
      setShowAggregatedActivities,
      showUpsertTerritoryRuleView,
      setShowUpsertTerritoryRuleView,
      blockSize,
      setBlockSize,
      selectedRowData,
      setSelectedRowData,
      errorItems,
      setErrorItems,
      selectedTerritory,
      setSelectedTerritory,
      selectedCell,
      setSelectedCell,
      selectedTerritoryRules,
      setSelectedTerritoryRules,
      showUpsertSellerAssignmentView,
      setShowUpsertSellerAssignmentView,
      showAccountQuotaDrillIn,
      setShowAccountQuotaDrillIn,
      showManageTerritoryQuotaDrillIn,
      setShowManageTerritoryQuotaDrillIn,
      selectedQuotaDrillInTerritory,
      setSelectedQuotaDrillInTerritory,
      isBulkDeleteChecked,
      setIsBulkDeleteChecked,
      bulkDeleteTerritoryJobKey,
      setBulkDeleteTerritoryJobKey,
      bulkDeleteExclusionIds,
      setBulkDeleteExclusionIds,
      bulkDeleteInclusionIds,
      setBulkDeleteInclusionIds,
      hasGridThresholdBeenExceeded,
      setHasGridThresholdBeenExceeded,
      bulkUpsertQuotaGridFields,
      setBulkUpsertQuotaGridFields
    }),
    [
      isQuotaAdjustmentEditable,
      isCommentEditable,
      sortModel,
      refreshGrid,
      owners,
      showActivities,
      showAggregatedActivities,
      showUpsertTerritoryRuleView,
      blockSize,
      selectedRowData,
      errorItems,
      selectedTerritory,
      selectedCell,
      selectedTerritoryRules,
      showUpsertSellerAssignmentView,
      showAccountQuotaDrillIn,
      showManageTerritoryQuotaDrillIn,
      selectedQuotaDrillInTerritory,
      isBulkDeleteChecked,
      bulkDeleteTerritoryJobKey,
      bulkDeleteExclusionIds,
      bulkDeleteInclusionIds,
      hasGridThresholdBeenExceeded,
      bulkUpsertQuotaGridFields
    ]
  );

  // Return the interface that we want to expose to our other components
  return <GridContext.Provider value={values}>{children}</GridContext.Provider>;
};

// Custom hook to read these values from
export const useGrid = (): GridContextValues => useContextSafe(GridContext);
