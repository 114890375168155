import React from 'react';

import { useBeforeunload } from 'react-beforeunload';
import { Prompt, useHistory } from 'react-router-dom';

import Dialog from 'components/Dialog/Dialog';

import { formatMessage } from 'utils/messages/utils';

interface DirtyPromptProps {
  dirty: boolean;
  submitCount: number;
  shouldUseCustomPrompt?: boolean;
  titleMessage?: string;
  bodyMessage?: string;
  onSubmit?: () => void;
  onClose?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

const DirtyPrompt: React.FC<DirtyPromptProps> = ({
  dirty,
  submitCount,
  shouldUseCustomPrompt = false,
  titleMessage = '',
  bodyMessage = '',
  confirmButtonText = formatMessage('REMAIN_ON_PAGE'),
  cancelButtonText = formatMessage('CLOSE'),
  onSubmit,
  onClose
}: DirtyPromptProps) => {
  const history = useHistory();

  // to support tab close and page refresh scenarios, when URL doesn't change
  useBeforeunload((event) => {
    if (dirty && submitCount === 0) {
      event.preventDefault();
      return formatMessage('UNSAVED_CHANGES');
    }
    return null;
  });

  return shouldUseCustomPrompt ? (
    <Dialog
      title={titleMessage}
      isOpen
      onClose={onClose}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
      onSubmit={onSubmit}
      size="small"
      bodyMinHeight={0}
      data-testid="custom-dirty-prompt"
    >
      {bodyMessage}
    </Dialog>
  ) : (
    <Prompt
      data-testid="prompt"
      when={dirty && submitCount === 0}
      message={(locationNext, action) => {
        // Check if the current location and the next are equal. If so, ignore. This prevents some double
        // confirmation alerts.
        if (history.location.key === locationNext.key) {
          return false;
        }
        return JSON.stringify({
          action,
          message: formatMessage('UNSAVED_CHANGES')
        });
      }}
    />
  );
};

export default DirtyPrompt;
