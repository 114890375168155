// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9\\+pAd {\n  display: flex;\n  width: 100%;\n  margin-bottom: var(--s);\n}\n\n  .fT6Ph {\n    display: none;\n  }\n\n  ._4Iz4A {\n      height: 24px;\n    }\n\n  .tiZQ6 {\n    margin-right: 2px;\n    margin-bottom: var(--xs);\n  }\n\n  .CqATQ {\n    color: var(--highlight-01);\n    background: var(--highlight-01-translucent);\n  }\n\n  .cBz1D {\n    color: var(--highlight-03);\n    background: var(--highlight-03-translucent);\n  }\n\n  .eVbcU {\n    max-width: 105px;\n    display: block !important;\n  }\n\n  .fKuTu {\n    display: flex;\n    min-width: 40px;\n  }\n\n  .yKALT {\n    align-self: flex-end;\n  }\n\n  ._9\\+pAd .bp3-button {\n    height: 24px;\n  }\n\n  ._9\\+pAd .bp3-button:hover {\n    background-color: transparent;\n  }\n\n  ._9\\+pAd .bp3-button:active {\n    background-color: transparent !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandableCustomHierarchyTags": "_9+pAd",
	"ExpandableCustomHierarchyTags_hidden": "fT6Ph",
	"ExpandableCustomHierarchyTags_tagsContainer__hideOverflowTags": "_4Iz4A",
	"ExpandableCustomHierarchyTags_tag": "tiZQ6",
	"ExpandableCustomHierarchyTags_filterKindTag": "CqATQ",
	"ExpandableCustomHierarchyTags_connectorTag": "cBz1D",
	"ExpandableCustomHierarchyTags_tagEllipsisText": "eVbcU",
	"ExpandableCustomHierarchyTags_controlButtonsContainer": "fKuTu",
	"ExpandableCustomHierarchyTags_hideOverflowTagsButton": "yKALT"
};
export default ___CSS_LOADER_EXPORT___;
