import { DefinitionFilterOperatorEnum } from 'app/graphql/generated/graphqlApolloTypes';

import { CollectionFilter, CollectionFilterKind, Segment, SegmentFilterClause } from 'app/models';

import { ReadonlyMapOrSet } from './territoryMapUtils';

type SegmentClauseEvaluator = (
  hierarchiesInClause: ReadonlyArray<{ hierarchyId: number }>,
  idsToTest: ReadonlyMapOrSet<number> | undefined
) => boolean;

const passesContainsAnySegmentClause: SegmentClauseEvaluator = (hierarchiesInClause, idsToTest) => {
  if (!idsToTest) return false;
  for (const hierarchy of hierarchiesInClause) {
    if (idsToTest.has(hierarchy.hierarchyId)) return true;
  }
  return false;
};
const passesNotContainsAnySegmentClause: SegmentClauseEvaluator = (hierarchies, idsToTest) => {
  return !passesContainsAnySegmentClause(hierarchies, idsToTest);
};

const passesEqualsSegmentClause: SegmentClauseEvaluator = (hierarchies, idsToTest) => {
  if (!idsToTest) return false;
  if (hierarchies.length !== idsToTest.size) return false;
  for (const hierarchy of hierarchies) {
    if (!idsToTest.has(hierarchy.hierarchyId)) return false;
  }
  return true;
};
const passesNotEqualsSegmentClause: SegmentClauseEvaluator = (hierarchies, idsToTest) => {
  return !passesEqualsSegmentClause(hierarchies, idsToTest);
};

const segmentFilterEvaluators: Record<DefinitionFilterOperatorEnum, SegmentClauseEvaluator> = {
  [DefinitionFilterOperatorEnum.CONTAINS_ANY]: passesContainsAnySegmentClause,
  [DefinitionFilterOperatorEnum.NOT_CONTAINS_ANY]: passesNotContainsAnySegmentClause,
  [DefinitionFilterOperatorEnum.EQUALS]: passesEqualsSegmentClause,
  [DefinitionFilterOperatorEnum.NOT_EQUALS]: passesNotEqualsSegmentClause
};

export const passesSegmentFilter = (
  filter: Pick<Segment, 'clauses'>,
  idsToTest: ReadonlyMapOrSet<number> | undefined
) => {
  // Currently only single-clause filters are supported
  if (filter.clauses.length !== 1) return false;
  const [clause] = filter.clauses;
  const evaluator = segmentFilterEvaluators[clause.operator];
  if (!evaluator) throw new Error(`Unsupported segment filter operator: ${clause.operator}`);
  return evaluator(clause.hierarchies, idsToTest);
};

// These enums are identical, but TS requires casting as they have different identities
const operatorAsFilterKind = (operator: DefinitionFilterOperatorEnum) => operator as string as CollectionFilterKind;

export const segmentClauseToCollectionFilter = (clause: SegmentFilterClause): CollectionFilter<number> => ({
  kind: operatorAsFilterKind(clause.operator),
  ids: clause.hierarchies.map((h) => h.hierarchyId)
});
